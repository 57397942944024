import { NEXT_STEP, SET_NOMINA_ID } from "../types";

export const setNomidaId = nominaId => ({
	type: SET_NOMINA_ID,
	payload: nominaId,
});

export const nextStep = step => ({
	type: NEXT_STEP,
	payload: step,
});
