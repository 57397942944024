import React, { useEffect, useState } from 'react';

export const DomicilioContext = React.createContext();

const DomicioProvider = props => {
  const [domicilioMain, setDomicilioMain] = useState({
    codigo_postal: '',
    estado: '',
    ciudad: '',
    colonia: '',
    calle: '',
    numero_exterior: '',
    numero_interior: '',
  });

  const updateDomicilio = valor => {
    setDomicilioMain(valor);
    localStorage.setItem('domicilioLocal', JSON.stringify(valor));
  };

  useEffect(() => {
    if (localStorage.getItem('domicilioLocal')) {
      const domicilioLocal = JSON.parse(localStorage.getItem('domicilioLocal'));
      setDomicilioMain(domicilioLocal);
    }
  }, []);

  return (
    <DomicilioContext.Provider value={{domicilioMain, updateDomicilio}}>
      {props.children}
    </DomicilioContext.Provider>
  );
};

DomicioProvider.propTypes = {};

export default DomicioProvider;
