import React, {useEffect, useState} from 'react';
import LOGO from '../../../assets/lOGO2.png';
import {Link} from 'react-router-dom';
import { TramiteCreditoContext } from '../../../context/TramiteCreditoProvider';
import {useHistory} from 'react-router';
import { UserContext } from '../../../context/UserProvider';
import facebook from '../../../assets/facebook.png';
import question from '../../../assets/question.png';

function FooterNomina(props){
    const {credito, updateCredito} = React.useContext(TramiteCreditoContext);
    const {userMain, updateUser} = React.useContext(UserContext);
    const user = userMain.nombre;
    const history = useHistory();
    var classe =  props.class;
    function redirecComponent(e: {preventDefault: () => void}) {
        e.preventDefault();

        if (credito.estatusSolicitud === 'aprobado') {
            if (credito.isDelivered) {
                return history.push('/credito-detalle-nomina');
            } else if (!credito.isDelivered) {
                return history.push('/dashboard-nomina');
            }
        } else if (credito.estatusSolicitud === 'revision') {
            return history.push('/proceso-credito-nomina');
        } else {
            return history.push('/sin-credito-nomina');
        }
    }


    function redirecEspacioSolicitud(e: {preventDefault: () => void}) {
        e.preventDefault();
    
        if (user != 'Iniciar Sesión') {
          redirecComponent(e);
        } else {
          return history.push('/step1-nomina');
        }
    }

    return(
        <footer >
        <div className="footer__first-container">
          <div>
            <img className="logo" src={LOGO} />
            <Link to="/aviso-de-privacidad-nomina">Aviso de privacidad</Link>
            <Link to="/terminos-y-condiciones-nomina">Términos y condiciones</Link>
            <Link to="/faq">Preguntas frecuentes</Link>
            <Link to="/derechos-arco-nomina">Derechos ARCO</Link>
            <a href="#" onClick={redirecEspacioSolicitud}>
              Quiero solicitar mi crédito
            </a>
          </div>
          <div className="footer__social-icons">
            <a
              target="_blank"
              href="https://www.facebook.com/PercapitaPrestamos"
            >
              <img src={facebook} />
            </a>
            <img src={question} />
          </div>
        </div>
        <div>
          <p>
          El Buró de Entidades Financieras contiene información de PROBENSO GROUP S.A. SOFOM E.N.R. sobre nuestro desempeño frente a los Usuarios, por la prestación de productos y servicios. Te invitamos a consultarlo también en la página <a href="https://www.buro.gob.mx" target="_blank">https://www.buro.gob.mx</a> ó a través de <a href="https://www.percapita.mx/Buro" target="_blank">https://www.percapita.mx/Buro</a>. Productos ofertados por Probenso Group, S.A., SOFOM, E.N.R., quién indica que, no requiere autorización de la Secretaría de Hacienda y Crédito Público para su constitución y realizar operaciones como Sociedad Financiera de Objeto Múltiple Entidad no Regulada y se encuentra sujeta a la supervisión de la Comisión Nacional Bancaria y de Valores, únicamente para efectos del artículo 56 de la Ley general de organizaciones y actividades auxiliares del crédito.
Ejemplo representativo: monto de crédito por $3000, con tasa anual de 300% y a un plazo de 12 semanas. Pago semanal $37154, tasa diaria .008%. Monto total a pagar: $4458 sin IVA. Sujeto aprobación de préstamo. CAT promedio para fines informativos 296.8%. Periodo mínimo y máximo para cancelación 10 días.
Es obligatorio cumplir con los siguientes requisitos: ser mayor de edad, nacionalidad mexicana, domicilio en territorio nacional y credencial para votar original vigente.
Percapita únicamente realiza operaciones en México.
Atención a usuarios de la Comisión Nacional para la Protección y Defensa de los Usuarios de Servicios Financieros, para cualquier cuestión respecto de los servicios financieros prestados por Percapita , los datos de contacto de la CONDUSEF son los siguientes: 55 5340-0999 asimismo le informa que la página de internet de la citada Comisión es <a href="https://www.condusef.gob.mx" target="_blank">www.condusef.gob.mx</a> y que el correo electrónico al que podrá enviar sus dudas es el <a href='mailto:asesoria@condusef.gob.mx'> asesoria@condusef.gob.mx.</a>
          </p>
        </div>
      </footer>
    )
}

export default FooterNomina;