
interface Coordinates {
    lat: number;
    long: number;
}


export default class GeolocationService {

    public activated: boolean;

    constructor() {
        if ("geolocation" in navigator) {
            this.activated = true;
        } else {
            this.activated = false;
        }
    }

    getCoordinates(): Promise<Coordinates> {
        return new Promise<Coordinates>((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(position => {
                resolve({
                    lat: position.coords.latitude,
                    long: position.coords.longitude
                })
            }, error =>{
                reject('Sin permiso')
            })
        });
    }



}