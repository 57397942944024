import {
    NEXT_STEP,
	SET_NOMINA_ID,
} from "../types";

const initialState = {
	step: "PERSONAL-INFORMATION",
};

const personalCreditReducer = (state = initialState, action) => {
	switch (action.type) {
		case NEXT_STEP:
			return {
				...state,
				step: action?.payload
			};
		case SET_NOMINA_ID:
			return {
				...state,
				nominaId: action?.payload
			};
		
        default:
			return state;
	}
};

export default personalCreditReducer;